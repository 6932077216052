'use strict';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

//Bootstrap Dropdowns - Display selected item at title
$(".dropdown-menu a").click(function(){
  $(this).parent().siblings('.btn').html($(this).text());
});


$("#simulate").click(function(event){
  event.preventDefault();
  var cno = getRandomInt(1, 4);
  $(this).closest('.widget').find('.c-holder').addClass('d-none');
  $(this).closest('.widget').find('.dc-' + cno).removeClass('d-none');
});


$("#simulateall").click(function(event){
  event.preventDefault();

  var pumpoff = $("#pumpoff option:selected").text();

  if ( pumpoff == 2.4){
      
    $('.sim-holder').addClass('d-none');
    $('.loader').removeClass('d-none');
    setTimeout(function(){ 
      $('.loader').addClass('d-none');
      $('.sim2').removeClass('d-none'); 
    },600);

    $('.a-sim').each(function() {
      var $this = $(this),
          countTo = $this.attr('data-count');
      
      $({ countNum: $this.text()}).animate({
        countNum: countTo
      },

      {

        duration: 1000,
        easing:'linear',
        step: function() {
          $this.text(Math.floor(this.countNum));
        },
        complete: function() {
          $this.text(this.countNum);
        }
      });  
    });
  } else
  {
    $('.sim-holder').addClass('d-none');
    $('.loader').removeClass('d-none');
    setTimeout(function(){ 
      $('.loader').addClass('d-none');
      $('.sim1').removeClass('d-none'); 
    },600);
    $('.a-box').each(function() {
      $(this).text('0');
    });
  }
});


//tO RESIZE THE CHARTS WHEN EXPANDING THE TABLE
$( document ).ready(function() {
  $(".collapse").on('shown.bs.collapse', function (e) {
    $(window).trigger('resize');
  });
});

// read hash from page load and change tab
var hash = document.location.hash;
var prefix = "tab_";
if (hash) {
    $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show');
} 

//Bootstrap 4 open tab
$('.tab-link').on('click', function(event) {
  // Prevent url change
  event.preventDefault();

  // `this` is the clicked <a> tag
  var target = $('[data-toggle="tab"][href="' + this.hash + '"]');
  
  // opening tab
  target.trigger('click');
  // scrolling into view
  target[0].scrollIntoView(true);
});
